// JavaScript File
import React from 'react';

import 'bootstrap/dist/css/bootstrap.css';


function Home(){
  return (
      
    <section className="relative pb-2">

    <div className='w-full h-[700px] bg-gray-900/90 absolute'>
        <img className='w-full h-full object-cover mix-blend-overlay' src="https://megax.s3.amazonaws.com/1.png" alt="airpotimg" />
    </div>


    <div className='max-w-[1240px] mx-auto text-white relative'>



    <center>
    <div className='px-4 py-12 sm:block hidden'>
    <div style={{ display: 'block', width: 350, padding: 10 }}>
      

 
  </div>
 
          <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'></h2>
          <h3 className='py-4 text-3xl text-slate-300 text-center'>We help you to realize your ideas with minimum budget possible</h3>
          <p className='py-4 text-3xl text-slate-300 text-center'>
            whether you are starting a small business or looking for Web and Mobile solutions experts that help you to realize your ideas, you are in the right place!
            We design and implement solutions matching your needs with a Budget orianted approach, we take advantages of diversable stack Technologies and the most 
            economical offers from the leading cloud and IT technology parties such as AWS, emailjs & Cloudflare
                
          </p>
   </div>
  </center>
  </div>


    </section>


  )
}  


export default Home;

